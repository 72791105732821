import Link from "next/link";

// EST -> UTC +5:00

export default function Start() {

	return (
		<div className="mt-16 text-center max-w-full">
			<h1 className="text-4xl">SEEDs</h1>
			<div className="mt-14">
				<Link href="/mint">
					<a className="bland special-underline">Purchase SEEDS</a>
				</Link>
			</div>
			<div className="mt-14">
				<Link href="/what">
					<a className="bland special-underline mt-14">What is a SEED?</a>
				</Link>
			</div>
			<div className="mt-14">
				<Link href="https://opensea.io/collection/seeds-luciensmith">
					<a rel="noreferrer" target="_blank" className="bland special-underline mt-14">View on OpenSea</a>
				</Link>
			</div>
			<div className="mt-14">
				<Link href="/view-trailer">
					<a className="bland special-underline mt-14">View Trailer</a>
				</Link>
			</div>
			<div className="mt-14">
				<Link href="/rarity">
					<a className="bland special-underline mt-14">Rarity</a>
				</Link>
			</div>
			<div className="mt-14">
				<Link href="/roadmap">
					<a className="bland special-underline mt-14">Roadmap</a>
				</Link>
			</div>
			<div className="mt-14">
				<Link href="/faq">
					<a className="bland special-underline mt-14">FAQ</a>
				</Link>
			</div>
		</div>
	);
}
